import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import "bootstrap/dist/css/bootstrap.min.css"
import './styles.scss'
import "react-datepicker/dist/react-datepicker.css"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)