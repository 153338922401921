import React, { useContext, useState, useEffect, ReactNode } from "react"
import { auth } from "../lib/firebase"
import { User, UserCredential, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, signOut } from "firebase/auth"

const AuthContext = React.createContext<{
    user: User | null
    login: (email: string, link: string) => Promise<UserCredential | null>
    logout: () => Promise<void>
    validateSignInLink: (link: string) => boolean
    sendSignInLink: (email: string) => Promise<void>
}>({
    user: null,
    login: async (email: string, link: string) => null,
    logout: async () => {},
    validateSignInLink: (link: string) => true,
    sendSignInLink: async (email: string) => {}
})

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)

    const login = (email: string, link: string) => {
        localStorage.removeItem('email')
        return signInWithEmailLink(auth, email, link)
    }
    const logout = () => signOut(auth)
    const validateSignInLink = (link: string) => isSignInWithEmailLink(auth, link)
    const sendSignInLink = (email: string) => {
        localStorage.setItem('email', email)
        const url = `${window.location.protocol}//${window.location.host}/login`
        return sendSignInLinkToEmail(auth, email, {
            url,
            handleCodeInApp: true,
        })
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const value = {
        user,
        login,
        logout,
        validateSignInLink,
        sendSignInLink,
    }

    return (
        <AuthContext.Provider value={value} >
            {!loading && children}
        </AuthContext.Provider>
    )
}