import axios from "axios"
import { Dispatch, SetStateAction } from "react"
import { Button } from "react-bootstrap"
import { useAuth } from "../../../../contexts/AuthCtx"

export default function AttendButton({
    bookingId,
    email,
    nickname,
    type,
    startAt,
    attended,
    loading,
    setLoading,
    setError,
}: {
    bookingId: string
    email: string
    nickname: string | null
    type: string
    startAt: string
    attended: boolean
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<string>>
}) {
    const { user } = useAuth()

    const updateAsAttended = async (bookingId: string, attended: boolean) => {
        if (!bookingId || attended === undefined) return

        setError("")
        setLoading(true)
        try {
            if (!user) return
            //@ts-ignore
            const { accessToken: token, email: coachEmail } = user
            const update = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/bookings/attend`, {
                bookingId,
                attended: !attended,
                customer: nickname || email,
                email,
                coachEmail,
                type,
                startAt,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            if (update.data !== 1) throw new Error('')
        } catch {
            setError('Failed updating')
        }

        setLoading(false)
    }

    return (
        <Button
            variant={attended ? 'danger' : 'primary'}
            onClick={() => updateAsAttended(bookingId, attended)}
            disabled={loading}
        >
            {attended ? 'Unattend' : 'Attend'}
        </Button>
    )
}