import axios from "axios"
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react"
import { Alert, Button, Form, Modal } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthCtx"
import { format } from "date-fns"
import ReactDatePicker from "react-datepicker"

export default function ReschedulePopup({
    sessionId,
    startAt,
    email,
    nickname,
    booking,
    loading,
    setLoading,
    closeHandler,
}: {
    sessionId: string
    startAt: string
    email: string
    nickname: string
    // availableAt: string
    // expireAt: string
    // price: {
    //     pt: number
    //     stretching: number
    // }
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    booking: ConfirmedBooking
    closeHandler: () => void
}) {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState("")
    // const [loading, setLoading] = useState(false)

    const startAtDateTime = new Date(startAt)
    const startAtDate = new Date(startAtDateTime.getTime() - startAtDateTime.getHours() * 60 * 60 * 1000)
    const [date, setDate] = useState(startAtDate)
    const [sessions, setSessions] = useState<Session[]>([])
    const [selectedSessionId, setSelectedSessionId] = useState('')

    const { user } = useAuth()

    const fetchAvailableSessions = async () => {
        setError("")
        setSessions([])
        setLoading(true)
        try {
            if (!user) return
            //@ts-ignore
            const { accessToken: token } = user
            const res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/fetch`, {
                dateRange: {
                    from: date < new Date() ? new Date() : date,
                    // from: date,
                    to: date,
                },
                availableOnly: true,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            setSessions(res.data)
            setLoading(false)
        } catch {
            setError('Failed fetching available sessions')
            setLoading(false)
        }
    }

    const changeTimeHandler = async (e: FormEvent) => {
        e.preventDefault()

        setError('')
        setLoading(true)
        try {
            //@ts-ignore
            const { accessToken: token } = user
            const res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/bookings/reschedule`, {
                bookingId: booking._id,
                sessionId: {
                    original: sessionId,
                    new: selectedSessionId,
                }
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })

            switch (res.data) {
                case 1: {
                    setSuccess(true)
                    break
                }
                case 2: {
                    setError('Booking is marked as attended.')
                    break
                }
                case 3: {
                    setError('Booking has been cancelled already.')
                    break
                }
                case 4: {
                    setError('Invalid parameters.')
                    break
                }
                case 11: {
                    setError('Invalid new sessionId.')
                    break
                }
                case 12: {
                    setError('Selected session is in the past.')
                    break
                }
                case 13: {
                    setError('Session is disabled.')
                    break
                }
                case 14: {
                    setError('Session has existing booking.')
                    break
                }
                case 21: {
                    setError('Unable to fetch customer email.')
                    break
                }

                // case 100: {
                //     throw new Error('')
                // }
                default: {
                    throw new Error('')
                }
            }
        } catch {
            setError('Unknown error happened')
        }
        setLoading(false)
    }

    useEffect(() => {
        setSelectedSessionId('')
        fetchAvailableSessions()
    }, [date])

    return (
        <Modal show={booking ? true : false} onHide={closeHandler}>
            <Modal.Dialog className="mx-4">
                <Modal.Header closeButton>
                    <Modal.Title>Change Booking Time</Modal.Title>
                </Modal.Header>

                <Form onSubmit={e => changeTimeHandler(e)}>
                    <Modal.Body>
                        Original booking:
                        <Modal.Dialog>
                            <ul>
                                <li>Email: {email}</li>
                                <li>Nickname: {nickname}</li>
                                <li>Type: {booking.type === 'pt' ? 'Training' : 'Stretching'}</li>
                                <li>Date: {format(new Date(startAt), 'dd-MMM (eee)')}</li>
                                <li>Time: {format(new Date(startAt), 'HH:mm')}</li>
                            </ul>
                        </Modal.Dialog>

                        <Form.Group>Change To</Form.Group>
                        <Form.Group>

                            <Form.Label className='me-2'>Date</Form.Label>
                            <Button
                                variant='secondary'
                                size='sm'
                                className="me-2"
                                onClick={() => setDate(new Date(date.getTime() - 24 * 60 * 60 * 1000))}
                                disabled={success || (date.getTime() - new Date().getTime()) < 0}
                            >
                                {'<'}
                            </Button>
                            <ReactDatePicker
                                selected={date}
                                onChange={(date) => date ? setDate(date) : null}
                                dateFormat="dd-MMM (eee)"
                                minDate={new Date()}
                                disabled={success}
                            />
                            <Button
                                variant='secondary'
                                size='sm'
                                className="ms-2"
                                onClick={() => setDate(new Date(date.getTime() + 24 * 60 * 60 * 1000))}
                                disabled={success}
                            >
                                {'>'}
                            </Button>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Sessions</Form.Label>
                            {sessions.map((_, i) => (
                                <div key={i}>
                                    <Form.Check
                                        onChange={() => setSelectedSessionId(_._id)}
                                        type='radio'
                                        name="startAt"
                                        id={_._id}
                                        label={format(new Date(_.startAt), 'dd-MMM (eee) HH:mm')}
                                        disabled={success || loading}
                                    />
                                </div>
                            ))}
                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" type='submit' disabled={loading || success}>
                            {loading ? 'loading...' : 'Confirm'}
                        </Button>
                    </Modal.Footer>
                    {error && <Alert className="mx-3" variant="danger">{error}</Alert>}
                    {success && <Alert className="mx-3" variant="success">Booking is successfully rescheduled.</Alert>}
                </Form>

            </Modal.Dialog>
        </Modal>
    )
}