import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthCtx"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
// import Dashboard from "./Dashboard"
import Login from "./logged-out/Login"
import PrivateRoute from "./logged-in/PrivateRoute"
import Subscribers from "./logged-in/Subscribers"
import Home from "./logged-in/Home"
// import PrivateRoute from "./PrivateRoute"
// import ForgotPassword from "./ForgotPassword"
// import UpdateProfile from "./UpdateProfile"
import Sessions from "./logged-in/Sessions"

export default function App() {
    return (
        <Container
            fluid
            className={`d-flex px-0 px-md-2`}
            style={{ minHeight: "100vh" }}
        >
            <div className="w-100 d-flex">
                <Router>
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={(<PrivateRoute>
                                <Home />
                            </PrivateRoute>)} />

                            <Route path="/subscribers" element={(<PrivateRoute>
                                <Subscribers />
                            </PrivateRoute>)} />

                            <Route path="/sessions" element={(<PrivateRoute>
                                <Sessions />
                            </PrivateRoute>)} />

                            {/* <PrivateRoute path="/update-profile" component={UpdateProfile} /> */}
                            <Route path="/login" element={<Login />} />
                            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                            <Route path="/*" element={<Navigate to="/" />} />
                        </Routes>
                    </AuthProvider>
                </Router>
            </div>
        </Container>
    )
}