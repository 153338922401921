import axios from "axios"
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useAuth } from "../../../../contexts/AuthCtx"

export default function CancelButton({
    bookingId,
    loading,
    setLoading,
    setError,
}: {
    bookingId: string
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<string>>
}) {
    const { user } = useAuth()
    const [cancelClickCount, setCancelClickCount] = useState<0 | 1>(0)

    const cancelBooking = async (e: MouseEvent) => {
        e.stopPropagation()
        if (cancelClickCount === 0) return setCancelClickCount(1)

        setError("")
        setLoading(true)
        try {
            if (!user) return
            //@ts-ignore
            const { accessToken: token } = user
            const cancel = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/bookings/cancel`, {
                bookingId
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })

            if (cancel.data === 100) throw new Error('')
            if (cancel.data === 2) setError('Session is starting within 60 minuets. Not allowed to cancel.')
            if (cancel.data === 3) setError('Already attended. Unable to cancel.')
        } catch {
            setError('Failed cancelling')
        }

        setLoading(false)
        setCancelClickCount(0)
    }

    useEffect(() => {
        const handler = () => setCancelClickCount(0)
        window.addEventListener('click', handler)
        return () => {
            window.removeEventListener('click', handler)
        }
    }, [])

    return (
        <Button
            onClick={(e: MouseEvent) => cancelBooking(e)}
            variant={cancelClickCount === 0 ? 'warning' : "danger"}
            disabled={loading}
        >
            {cancelClickCount === 0 ? 'Cancel' : 'Confirm cancellation. (Cancelling within 8 hours will only refund 90%.)'}
        </Button>
    )
}