import { useState } from "react"
import { Card, Button, Alert, Nav } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthCtx"
import { Link, useNavigate } from "react-router-dom"

export default function Home() {
    const [error, setError] = useState('')
    const { user, logout } = useAuth()
    const navigate = useNavigate()

    async function handleLogout() {
        setError("")
        if (!logout) return setError('Internal server error')

        try {
            await logout()
            navigate("/login")
        } catch {
            setError("Failed to log out")
        }
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Profile</h2>
                    <strong>Email:</strong> {user?.email}
                </Card.Body>
            </Card>

            <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>
                    Log Out
                </Button>
            </div>
        </>
    )
}