import axios from "axios"
import { Dispatch, SetStateAction, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import { useAuth } from "../../../contexts/AuthCtx"

export default function UpdateExpiryPopup({
    selectedSessionUpdateExpiry: session,
    setSelectedSessionUpdateExpiry,
    loading,
    setLoading,
    setError,
}: {
    selectedSessionUpdateExpiry: SelectedSessionUpdateExpiry
    setSelectedSessionUpdateExpiry: Dispatch<SetStateAction<SelectedSessionUpdateExpiry | null>>
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<string>>
}) {
    const { user } = useAuth()

    const [newExpireAt, setNewExpireAt] = useState(new Date(session.expireAt))
    const closeHandler = () => setSelectedSessionUpdateExpiry(null)

    const updateExpiry = async () => {
        setError("")
        setLoading(true)
        try {
            if (!user) return
            //@ts-ignore
            const { accessToken: token } = user
            await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/update-expiry`, {
                sessionId: session.sessionId,
                expireAt: newExpireAt.getTime(),
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
        } catch {
            setError('Failed updating expiry')
        }

        setLoading(false)
        setSelectedSessionUpdateExpiry(null)
    }

    return (
        <Modal show={true} onHide={closeHandler}>
            <Modal.Dialog className="mx-4">
                <Modal.Body>
                    <Form
                    // onSubmit={e => changeTimeHandler(e)}
                    >

                        Original expiry: {session.expireAt}

                        <Modal.Dialog>
                            New expiry:
                            <ReactDatePicker
                                selected={newExpireAt}
                                onChange={(date) => date ? setNewExpireAt(date) : null}
                                dateFormat="dd-MMM (eee) HH:ss"
                                showTimeSelect
                                timeIntervals={60}
                            />
                        </Modal.Dialog>

                        <Button
                            onClick={() => updateExpiry()}
                            variant={'info'}
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}