import axios from "axios"
import { FormEvent, useEffect, useState } from "react"
import { Alert, Table } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthCtx"
import Schedule from "./Schedule"
import SessionBookingItem from "./SessionBookingItem"
import { format } from 'date-fns'
import ReschedulePopup from "./ReschedulePopup"
import DateRefreshCard from "./DateRefreshCard"
import UpdateExpiryPopup from "./UpdateExpiryPopup"

export default function Sessions() {
    const [error, setError] = useState("")
    const [fetchingError, setFetchingError] = useState("")
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [sessionBookings, setSessionBookings] = useState<SessionBooking[]>([])
    const { user } = useAuth()
    const [selectedRescheduleBooking, setSelectedRescheduleBooking] = useState<SelectedRescheduleBooking | null>(null)
    const [selectedSessionUpdateExpiry, setSelectedSessionUpdateExpiry] = useState<SelectedSessionUpdateExpiry | null>(null)

    const nowDateStr = format(new Date(), 'yyyy-MM-dd')
    const [date, setDate] = useState(new Date(nowDateStr))

    async function fetchSessions(e?: FormEvent) {
        if (e) e.preventDefault()
        if (!user) return

        setFetchingError('')
        setFetching(true)
        try {
            //@ts-ignore
            const { accessToken: token } = user
            const utcDate = new Date(new Date(date).getTime() - 8 * 60 * 60 * 1000)
            const res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/fetch`, {
                dateRange: {
                    from: utcDate,
                    to: utcDate,
                },
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            setSessionBookings(res.data)
        } catch {
            setFetchingError('Failed fetching')
        }
        setFetching(false)
    }

    useEffect(() => {
        if (loading) return
        fetchSessions()
    }, [loading])

    useEffect(() => {
        fetchSessions()
    }, [date])

    return (
        <>
            <div className="d-flex flex-column flex-md-row mb-3">
                <DateRefreshCard date={date} setDate={setDate} fetchSessions={fetchSessions} disabled={loading || fetching} />
                <Schedule loading={loading} setLoading={setLoading} date={date} />
            </div>

            {error && <Alert variant="danger">{error}</Alert>}
            {fetchingError && <Alert variant="danger">{fetchingError}</Alert>}

            <Table striped bordered hover size='sm' responsive>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        {/* <th>PT $</th>
                        <th>S $</th> */}
                        <th>Expiry</th>
                        <th>Nickname</th>
                        <th>Email</th>
                        <th>Session type</th>
                        {/* <th>Attended?</th> */}
                        <th>Mark as attend</th>
                        <th>Reschedule</th>
                        <th>Cancel booking</th>
                        {/* <th>Enabled</th> */}
                        <th>Session actions</th>
                    </tr>
                </thead>
                
                <tbody style={{ opacity: fetching ? .25 : undefined }}>
                    {sessionBookings.map((_, i) => (
                        <SessionBookingItem
                            key={i}
                            sessionBooking={_}
                            loading={loading}
                            setLoading={setLoading}
                            setError={setError}
                            setSelectedRescheduleBooking={setSelectedRescheduleBooking}
                            setSelectedSessionUpdateExpiry={setSelectedSessionUpdateExpiry}
                        />
                    ))}
                </tbody>
            </Table>

            {
                !selectedRescheduleBooking || !selectedRescheduleBooking.sessionBooking.booking ? null : (
                    <ReschedulePopup
                        sessionId={selectedRescheduleBooking.sessionBooking._id}
                        startAt={selectedRescheduleBooking.sessionBooking.startAt}
                        booking={selectedRescheduleBooking.sessionBooking.booking}
                        email={selectedRescheduleBooking.email}
                        nickname={selectedRescheduleBooking.nickname}
                        closeHandler={() => setSelectedRescheduleBooking(null)}
                        loading={loading}
                        setLoading={setLoading}
                    />
                )
            }

            {
                !selectedSessionUpdateExpiry ? null : (
                    <UpdateExpiryPopup
                        selectedSessionUpdateExpiry={selectedSessionUpdateExpiry}
                        setSelectedSessionUpdateExpiry={setSelectedSessionUpdateExpiry}
                        loading={loading}
                        setLoading={setLoading}
                        setError={setError}
                    />
                )
            }
        </>
    )
}