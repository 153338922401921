import axios from "axios"
import { Dispatch, SetStateAction } from "react"
import { Button } from "react-bootstrap"
import { useAuth } from "../../../../contexts/AuthCtx"

export default function ToggleButton({
    sessionId,
    enabled,
    loading,
    setLoading,
    setError,
}: {
    sessionId: string
    enabled: boolean
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<string>>
}) {
    const { user } = useAuth()

    const toggleSession = async () => {
        setError("")
        setLoading(true)
        try {
            if (!user) return
            //@ts-ignore
            const { accessToken: token } = user
            await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/toggle`, {
                sessionId,
                enabled: !enabled,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            setLoading(false)
        } catch {
            setError('Failed toggling session')
            setLoading(false)
        }
    }

    return (
        <Button
            onClick={() => toggleSession()}
            variant={enabled ? 'danger' : 'primary'}
            disabled={loading}
        >
            {enabled ? 'Disable' : 'Enable'}
        </Button>
    )
}