import { Dispatch, FormEvent, SetStateAction } from "react"
import { Button, Form, Card } from "react-bootstrap"
import DatePicker from "react-datepicker"

export default function DateRefreshCard({
    date,
    setDate,
    fetchSessions,
    disabled,
}: {
    date: Date
    setDate: Dispatch<SetStateAction<Date>>
    fetchSessions: (e?: FormEvent) => Promise<void>
    disabled: boolean
}) {
    return (
        <Card
            className="me-0 me-md-2 mb-2 mb-md-2"
            style={{ minWidth: 300, maxWidth: 400 }}
        >
            <Card.Body>
                <Form onSubmit={fetchSessions}>
                    <div className="d-flex align-items-end justify-content-start">
                        <Form.Group className="me-2 d-flex flex-column">
                            <Form.Label className="pe-2">
                                Date
                            </Form.Label>

                            <div className="d-flex">
                                <Button
                                    variant='secondary'
                                    size='sm'
                                    className="me-2"
                                    onClick={() => setDate(new Date(date.getTime() - 24 * 60 * 60 * 1000))}
                                >
                                    {'<'}
                                </Button>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => date ? setDate(date) : null}
                                    dateFormat="dd-MMM (eee)"
                                />
                                <Button
                                    variant='secondary'
                                    size='sm'
                                    className="ms-2"
                                    onClick={() => setDate(new Date(date.getTime() + 24 * 60 * 60 * 1000))}
                                >
                                    {'>'}
                                </Button>
                            </div>
                        </Form.Group>
                        
                        <Button
                            variant='primary'
                            type='submit'
                            disabled={disabled}
                        >
                            Refresh
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}