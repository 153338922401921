import { FormEvent, Dispatch, SetStateAction, useRef, useState, useEffect } from "react"
import { Card, Button, Alert, Form, Accordion } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthCtx"
import axios from "axios"
import { format } from "date-fns"
import formatDateInHkt from "../../../lib/formatDateInHkt"

export default function Schedule({
    loading,
    setLoading,
    date,
}: {
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    date: Date
}) {
    const [error, setError] = useState('')
    const { user } = useAuth()
    const timeRef = useRef<HTMLSelectElement>(null)
    const sessionCountRef = useRef<HTMLInputElement>(null)
    const ptRef = useRef<HTMLInputElement>(null)
    const stretchingRef = useRef<HTMLInputElement>(null)
    const [activeKey, setActiveKey] = useState('')

    const [sessions, setSessions] = useState<number[]>([])
    const update = () => {
        if (!timeRef.current || !sessionCountRef.current) return
        const startDateTime = date.getTime() + (parseInt(timeRef.current.value) - 8) * 60 * 60 * 1000
        const sessionCount = sessionCountRef.current.value
        setSessions(Array.from({ length: parseInt(sessionCount) }, (_, i) => startDateTime + i * 3600 * 1000))
    }

    useEffect(() => {
        update()
    }, [date])

    async function handler(e?: FormEvent) {
        if (e) e.preventDefault()

        setError("")
        setLoading(true)
        try {
            if (!ptRef.current || !stretchingRef.current) {
                // throw new Error('')
                return
            }
            const price = {
                pt: ptRef.current.value,
                stretching: stretchingRef.current.value,
            }
            //@ts-ignore
            const { accessToken: token } = user
            await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/sessions/create`, {
                uid: 'chris',
                sessions: sessions.map(_ => {
                    const date = new Date(_)
                    const wkDay = date.getDay() === 0 ? 7 : date.getDay()
                    const midnight = date.getTime() - date.getHours() * 60 * 60 * 1000 - date.getMinutes() * 60 * 1000
                    const availableAt = midnight - wkDay * 24 * 60 * 60 * 1000
                    const expireAt = availableAt + 24 * 60 * 60 * 1000
                    return {
                        startAt: _,
                        availableAt,
                        expireAt,
                        price,
                    }
                }),
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            setActiveKey('')
        } catch {
            setError("Failed scheduling sessions")
        }
        setLoading(false)
    }

    return (
        <Accordion
            style={{ minWidth: 300, maxWidth: 400 }}
            activeKey={activeKey}
        >
            <Accordion.Item eventKey='0'>
                <Accordion.Header onClick={() => setActiveKey(prev => prev === '0' ? '' : '0')}>
                    <Card.Title>Schedule Sessions</Card.Title>
                </Accordion.Header>
                <Accordion.Body>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <Form onSubmit={handler}>
                        <div className="d-flex">
                            <Form.Group className='col-6 px-1'>
                                <Form.Label>Date</Form.Label>
                                <Form.Control type='text' name='date' required value={format(date, 'dd-MMM (eee)')} disabled onChange={update} />
                            </Form.Group>
                            <Form.Group className='col-6 px-1'>
                                <Form.Label>Start time</Form.Label>
                                <Form.Select ref={timeRef} defaultValue={10} onChange={update}>
                                    {Array.from(Array(24).keys()).map((_) => (
                                        <option key={_} value={_}>{_ < 10 ? '0' + _ : _}:00</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <Form.Group className="px-1">
                            <Form.Label># sessions</Form.Label>
                            <Form.Control type='range' name='sessions' required ref={sessionCountRef} defaultValue={1} min={1} max={14} onChange={update} />
                        </Form.Group>
                        <div className="d-flex">
                            <Form.Group className='col-6 px-1'>
                                <Form.Label>PT Price</Form.Label>
                                <Form.Control type='number' name='pt' required ref={ptRef} defaultValue={600} min={300} max={2000} disabled />
                            </Form.Group>
                            <Form.Group className='col-6 px-1'>
                                <Form.Label>Stretching Price</Form.Label>
                                <Form.Control type='number' name='stretching' required ref={stretchingRef} defaultValue={750} min={300} max={2000} disabled />
                            </Form.Group>
                        </div>
                        <ul>
                            {sessions.map((_, i) => (
                                <li key={i}>
                                    {formatDateInHkt(new Date(_), 'dd MMM yyyy, HH:mm')} HKT
                                </li>
                            ))}
                        </ul>
                        <Button type='submit' variant='primary' className="w-100 mt-3" disabled={loading || !sessions.length}>
                            Confirm
                        </Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}