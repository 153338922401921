import axios from "axios"
import { FormEvent, useEffect, useRef, useState } from "react"
import { Button, Alert, Table, Modal, Form } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthCtx"
import SubItem from "./SubItem"

export default function Subscribers() {
    const [msg, setMsg] = useState("")
    const [error, setError] = useState("")
    const [subscribers, setSubscribers] = useState<Subscriber[] | null>(null)
    const { user } = useAuth()
    const ref = useRef<HTMLInputElement>(null)
    const [selected, setSelected] = useState<Subscriber | null>(null)
    const [fetching, setFetching] = useState(false)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    async function load() {
        if (!user) return
        try {
            setFetching(true)
            //@ts-ignore
            const { accessToken: token } = user
            const res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/subscriptions/list`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            setSubscribers(res.data)
        } catch {
            setError('Failed fetching')
        }
        setFetching(false)
    }

    useEffect(() => {
        if (loading) return
        load()
    }, [loading])

    const topup = async (e: FormEvent) => {
        e.preventDefault()
        if (!ref.current || !selected) return

        setMsg('')
        setError('')
        setLoading(true)
        try {
            if (!user) return
            //@ts-ignore
            const { accessToken: token } = user
            const topup = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/subscriptions/topup`, {
                stripeId: selected.customer.id,
                amount: -parseInt(ref.current.value),
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            if (topup.data !== 1) throw new Error('')
            setMsg('Successful topping up')
        } catch {
            setError('Failed topping up')
        }

        setSelected(null)
        setLoading(false)
    }

    const filter = (e: FormEvent) => {
        e.preventDefault()
        // setSearch()
        //@ts-ignore
        setSearch((e.target.search.value as string).toLowerCase())
    }

    const filteredSubscribers = subscribers?.filter(_ => (
        _.customer.email.toLowerCase().indexOf(search) > -1 ||
        _.customer.nickname?.toLowerCase().indexOf(search) > -1 ||
        _.plan.toLowerCase().indexOf(search) > -1)
    ) || []

    return (
        <>
            <div className="d-flex align-items-center justify-content-between my-2">
                <Form onSubmit={filter}>
                    <div className="d-flex align-items-end justify-content-start">
                        <Form.Group className="mx-1">
                            <Form.Control placeholder='search' type='text' name='search' />
                        </Form.Group>
                        <Button variant='primary' className="mx-1" type='submit' disabled={subscribers === null || fetching || loading}>Search</Button>
                        <Button variant='secondary' onClick={() => setSearch('')} type='reset' disabled={subscribers === null || fetching || loading}>Reset</Button>
                    </div>
                </Form>
                <Button variant='info' onClick={load} disabled={subscribers === null || fetching || loading}>Refresh</Button>
            </div>

            {msg && <Alert variant="success">{msg}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}

            <Table striped bordered hover responsive size='sm'
                style={{ maxWidth: "100%" }}
            >
                <thead>
                    <tr>
                        {/* <th>id</th> */}
                        <th>email</th>
                        <th>name</th>
                        <th>plan</th>
                        <th>start date</th>
                        <th>end at</th>
                        <th>balance</th>
                        <th>Top up</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredSubscribers.map((_, i) => (
                        <SubItem key={i} sub={_} setSelected={setSelected} disabled={subscribers === null || fetching || loading} />
                    ))}
                </tbody>
            </Table>


            <Modal show={selected ? true : false} onHide={() => setSelected(null)}>
                <Modal.Dialog className="mx-4">
                    <Modal.Header closeButton>
                        <Modal.Title>Cash Top-up</Modal.Title>
                    </Modal.Header>

                    <Form onSubmit={e => topup(e)}>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Customer</Form.Label>
                                <Form.Control type='string' name='email' disabled required value={selected?.customer.email} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type='number' name='pt' disabled={loading} ref={ref} required defaultValue={400} min={100} max={2000} />
                            </Form.Group>

                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="primary" type='submit' disabled={loading}>Confirm</Button>
                        </Modal.Footer>
                    </Form>

                </Modal.Dialog>
            </Modal>
        </>
    )
}