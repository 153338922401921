import { FormEvent, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthCtx"
import { Link, useNavigate } from "react-router-dom"

export default function Login() {
    const emailRef = useRef<HTMLInputElement>(null)
    const { user, login, validateSignInLink, sendSignInLink } = useAuth()
    const [sent, setSent] = useState(false)
    const [isValidLink, setIsValidLink] = useState(false)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (user) return navigate('/')
        const lsEmail = localStorage.getItem('email')
        if (isValidLink && emailRef.current !== null && lsEmail !== null) {
            emailRef.current.value = lsEmail
            handleSubmit()
            return
        }
        if (validateSignInLink(window.location.href)) return setIsValidLink(true)
    }, [user, isValidLink])

    async function handleSubmit(e?: FormEvent) {
        if (e) e.preventDefault()

        if (isValidLink) {
            (async () => {
                try {
                    if (!emailRef.current) return
                    await login(emailRef.current.value, window.location.href)
                    navigate('/')
                } catch (err) {
                    console.error(err)
                    setError("Failed to log in")
                }
            })()
            return
        }

        try {
            if (!emailRef.current) return
            setError("")
            setLoading(true)
            await sendSignInLink(emailRef.current.value)
            setSent(true)
        } catch (err) {
            console.error(err)
            setError("Failed to log in")
        }
    }

    return (
        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">Log In</h2>
                {sent && <Alert variant='success'>Login link sent to your email</Alert>}
                {isValidLink && <Alert variant='info'>Please enter your email again</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" ref={emailRef} required disabled={loading} />
                    </Form.Group>

                    <Button disabled={loading} className="w-100 mt-2" type="submit">
                        Log In
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    )
}