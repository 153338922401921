import { Nav } from "react-bootstrap"
// import { useState } from "react"

export default function Layout({ children }: { children: React.ReactNode }) {
    // const [vis, setVis] = useState(false)
    return (
        <>
            <Nav className='col-1 col-md-1 d-block bg-light sidebar' style={{overflow:'hidden'}}>
                <Nav.Item>
                    <Nav.Link href='/'>Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href='/subscribers'>Subscribers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href='/Sessions'>Sessions</Nav.Link>
                </Nav.Item>
            </Nav>
            <main className='col-11 col-md-11 px-1 bd-main order-1'>
                {children}
            </main>
        </>
    )
}