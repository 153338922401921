import React from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../../contexts/AuthCtx"
import Layout from "./Layout"

export default function PrivateRoute({
    children,
}: {
    children: React.ReactNode
}): JSX.Element {
    const { user } = useAuth()
    return (
        <>
            {user ? (
                <Layout>{children}</Layout>
            ) : (
                <Navigate to="/login" />
            )}
        </>
    )
}