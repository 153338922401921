import axios from "axios"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useAuth } from "../../../../contexts/AuthCtx"
import styles from './index.module.css'
import CancelButton from "./CancelButton"
import AttendButton from "./AttendButton"
import ToggleButton from "./ToggleButton"
import { Button } from "react-bootstrap"
import formatDateInHkt from "../../../../lib/formatDateInHkt"

export default function SessionBookingItem({
    sessionBooking: _,
    loading,
    setLoading,
    setError,
    setSelectedRescheduleBooking,
    setSelectedSessionUpdateExpiry,
}: {
    sessionBooking: SessionBooking
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<string>>
    setSelectedRescheduleBooking: Dispatch<SetStateAction<SelectedRescheduleBooking | null>>
    setSelectedSessionUpdateExpiry: Dispatch<SetStateAction<SelectedSessionUpdateExpiry | null>>
}) {
    const { user } = useAuth()

    const [email, setEmail] = useState(null)
    const [nickname, setNickname] = useState(null)
    useEffect(() => {
        const asyncFx = async () => {
            if (!_.booking) {
                setEmail(null)
                setNickname(null)
                return
            }

            try {

                if (!user) return
                //@ts-ignore
                const { accessToken: token } = user
                const res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/gym/admin/subscriptions/get-customer`, {
                    stripeId: _.booking.stripeId,
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    }
                })
                if (!res.data) return
                setEmail(res.data.email)
                setNickname(res.data.nickname)
            } catch {

            }
        }
        asyncFx()
    }, [_.booking?.stripeId || ''])

    return (
        <tr className={_.enabled ? undefined : styles.disabled}>
            <td style={{ minWidth: 110 }}>{formatDateInHkt(new Date(_.startAt), 'dd-MMM (eee)')}</td>
            <td style={{ minWidth: 40 }}>{formatDateInHkt(new Date(_.startAt), 'HH:ss')}</td>
            {/* <td style={{ minWidth: 45 }}>{_.price.pt}</td>
            <td style={{ minWidth: 45 }}>{_.price.stretching}</td> */}
            <td style={{ minWidth: 60 }}>{formatDateInHkt(new Date(_.expireAt), 'dd-MMM (eee) HH:ss')}</td>

            <td style={{ minWidth: 40 }}>{nickname}</td>
            <td style={{ minWidth: 60 }}>{email}</td>
            <td style={{ minWidth: 40 }}>{!(_.booking) ? null : _.booking.type === 'pt' ? 'Training' : 'Stretching'}</td>
            {/* <td style={{ minWidth: 40 }}>{!_.booking ? '' : _.booking.attended ? 'Y' : 'N'}</td> */}

            <td style={{ minWidth: 60 }}>
                {!_.booking || !email ? null : (
                    <AttendButton
                        bookingId={_.booking._id}
                        email={email}
                        nickname={nickname}
                        type={_.booking.type}
                        startAt={_.startAt}
                        attended={_.booking.attended}
                        loading={loading}
                        setLoading={setLoading}
                        setError={setError}
                    />
                )}
            </td>

            <td style={{ minWidth: 60 }}>
                {!_.booking || _.booking.attended || !email || !nickname ? null : (
                    <Button
                        onClick={() => setSelectedRescheduleBooking({
                            sessionBooking: _,
                            email,
                            nickname,
                        })}
                        variant='secondary'
                    >
                        Reschedule
                    </Button>
                )}
            </td>

            <td style={{ minWidth: 60 }}>
                {_.booking && !_.booking.attended && new Date(_.startAt) >= new Date() ? (
                    <CancelButton
                        bookingId={_.booking._id}
                        loading={loading}
                        setLoading={setLoading}
                        setError={setError}
                    />
                ) : null
                }
            </td>

            {/* <td style={{ minWidth: 50 }}>{_.enabled ? 'Y' : 'N'}</td> */}

            <td style={{ minWidth: 50 }}>
                {_.booking ? null : (
                    <>
                        <ToggleButton
                            sessionId={_._id}
                            enabled={_.enabled}
                            loading={loading}
                            setLoading={setLoading}
                            setError={setError}
                        />

                        <Button
                            onClick={() => setSelectedSessionUpdateExpiry({
                                sessionId: _._id,
                                expireAt: _.expireAt,
                            })}
                            variant={'info'}
                            disabled={loading}
                        >
                            Update Expiry
                        </Button>
                    </>
                )}
            </td>
        </tr>
    )
}