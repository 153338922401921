import { Button } from "react-bootstrap"
import { format } from 'date-fns'
import { Dispatch, SetStateAction } from "react"

export default function SubItem({
    sub: _,
    setSelected,
    disabled,
}: {
    sub: Subscriber
    setSelected: Dispatch<SetStateAction<Subscriber | null>>
    disabled: boolean
}) {
    const balance = parseInt(_.customer.balance) / -100
    return (
        <tr>
            {/* <td>{_.customer.id}</td> */}
            <td style={{ minWidth: 100 }}>{_.customer.email}</td>
            <td style={{ minWidth: 100 }}>{_.customer.nickname}</td>
            <td style={{ minWidth: 100 }}>{_.plan}</td>
            <td style={{ minWidth: 100 }}>{format(parseInt(_.startDate) * 1000, 'dd-MMM-yy')}</td>
            <td style={{ minWidth: 100 }}>{format(parseInt(_.current_period_end) * 1000, 'dd-MMM-yy')}</td>
            <td style={{ minWidth: 100 }}>${(balance || 0).toLocaleString('en-Us')}</td>
            <td style={{ minWidth: 100 }}>
                <Button onClick={() => setSelected(_)} disabled={disabled}>Top up</Button>
            </td>
        </tr>
    )
}